import { request } from "@/utils/request"

export function createOrder(data) {
    return request({
        url: "api/facebook-sv4/create",
        method: "post",
        data
    })
}
export function getListOrder(param = null) {
    return request({
        url: "api/facebook-sv4" + (param ? param : ""),
        method: "get"
    })
}
export function removeOrder(data) {
    return request({
        url: "api/facebook-sv4/remove",
        method: "post",
        data
    })
}
