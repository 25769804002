<template>
    <div>
        <div class="row mb-3">
            <div class="col-md-4 col-12 mb-3 mb-md-0">
                <div class="row align-items-center">
                    <div class="col-md-auto col-12">
                        <h6 class="bold mb-0">Tìm theo Object ID:</h6>
                    </div>
                    <div class="col-md col-12">
                        <input
                            @change="getListJobOrder()"
                            class="form-control input-light"
                            type="text"
                            placeholder="Nhập ID cần tìm"
                            required=""
                            v-model="object_id_search"
                        />
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-12">
                <div class="row align-items-center">
                    <div class="col-md-auto col-12">
                        <h6 class="bold mb-0">Số nhật ký hiển thị tối đa:</h6>
                    </div>
                    <div class="col-md col-12">
                        <select @change="getListJobOrder()" v-model="limit" class="custom-select select-light">
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <datatable stt="true" :columns="type != 'review' ? columnsTableLogs : columnsTableReview" :rows="data">
            <th slot="thead-tr-o" class="text-center" style="vertical-align: middle">Thao Tác</th>
            <td slot="tbody-tr-o" slot-scope="props" class="text-center" style="vertical-align: middle">
                <button
                    @click="removeOrder(props.row)"
                    :disabled="
                        props.row.quantity == props.row.count_is_run ||
                            props.row.status == 2 ||
                            props.row.is_refund == 1 ||
                            props.row.is_removed == 1
                    "
                    class="btn btn-danger btn-sm ml-2"
                >
                    <i class="far fa-check-circle"></i> Hủy Order
                </button>
            </td>
        </datatable>
    </div>
</template>
<script>
import { catchError } from "../../../../helpers"
import datatable from "../../../../components/datatable/datatable"
import moment from "moment"
import * as sv4Api from "../../../../api/services/facebook-sv4"

export default {
    name: "facebook-logs-sv4",
    components: {
        datatable
    },
    props: {
        panel: String,
        type: String
    },
    data() {
        return {
            object_id_search: "",
            limit: 100,
            data: [],
            columnsTableLogs: [
                { label: "Username", field: "username" },
                { label: "ID Seeding", field: "object_id", fb_link: true },
                { label: "Loại", field: "type_html" },
                { label: "Tốc độ", field: "speed_name" },
                { label: "Bảo hành", field: "warranty", html: true },
                { label: "Start", field: "start_like", html: true },
                { label: "Số lượng", field: "quantity", numeric: true },
                { label: "Giá", field: "price_per", numeric: true },
                { label: "Tổng tiền", field: "prices", numeric: true },
                { label: "Trạng thái", field: "status_html", html: true },
                { label: "Khởi tạo", field: "created_at", timeago: true },
                { label: "Hết hạn", field: "time_expired_format" },
                { label: "Ghi chú", field: "notes", inputtext: true }
            ],
            columnsTableReview: [
                { label: "Username", field: "username" },
                { label: "ID Seeding", field: "object_id", fb_link: true },
                { label: "Loại", field: "type_html" },
                { label: "Số lượng", field: "quantity", numeric: true },
                { label: "Giá", field: "price_per", numeric: true },
                { label: "Tổng tiền", field: "prices", numeric: true },
                { label: "Trạng thái", field: "status_html", html: true },
                { label: "Khởi tạo", field: "created_at", timeago: true },
                { label: "Hết hạn", field: "time_expired_format" },
                { label: "Nội dung", field: "list_messages_html", inputtext: true },
                { label: "Ghi chú", field: "notes", inputtext: true }
            ]
        }
    },
    computed: {
        currency() {
            return this.$store.state.site.site.currency
        }
    },
    watch: {
        panel(value) {
            if (value == "logs-sv4") {
                this.getListJobOrder()
            }
        },
        type() {
            if (this.panel === "logs-sv4") {
                this.getListJobOrder()
            }
        }
    },
    methods: {
        getListJobOrder: async function() {
            let type_param = this.type
            let param =
                "?limit=" +
                this.limit +
                (this.object_id_search ? "&object_id=" + this.object_id_search : "") +
                ("&type=" + type_param)
            this.data = []
            let data = await sv4Api.getListOrder(param)
            if (data.data) {
                this.data = data.data.map(item => {
                    item.prices = parseInt(item.price_per) * parseInt(item.quantity)
                    item.type_html = item.type.toUpperCase()
                    item.status_html =
                        item.object_not_exist || item.is_hidden || (item.is_refund && item.count_refund > 0)
                            ? `<span class="badge badge-danger"><i class="material-icons font-14 icon-log">info</i>${item.status_message}</span>`
                            : item.status !== 2
                            ? `<span class="badge badge-yellow"><i class="material-icons font-14 icon-log">autorenew</i>${item.status_message}</span>`
                            : `<span class="badge badge-success"><i class="material-icons font-14 icon-log">beenhere</i>${item.status_message}</span>`
                    item.warranty =
                        item.is_warranty === 1
                            ? '<span class="badge badge-success">Có bảo hành</span>'
                            : '<span class="badge badge-info">Không bảo hành</span>'
                    item.list_messages_html = item.list_messages ? JSON.parse(item.list_messages).join("\n") : ""
                    item.time_expired_format = this.convertDate(item.time_end)
                    item.speed_name = this.speedName(item.speed)
                    return item
                })
            }
        },
        removeOrder: async function(item) {
            let cancel = false
            await this.$swal({
                title: `HỦY ${item.type.toUpperCase()} ID: ${item.object_id} ?`,
                html: `Sau khi hủy bạn sẽ mất số lượng ${item.type.toUpperCase()} chưa tăng và trừ thêm 5.000 ${
                    this.currency
                } phí để tránh Spam !`,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Đồng Ý",
                cancelButtonText: "Không",
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    cancel = true
                }
            })
            if (cancel) {
                const id = item.id
                let data = await sv4Api.removeOrder({
                    id: id
                })
                if (data.status === 200 && data.success) {
                    this.data = this.data.map(job => {
                        if (job.orders_id === item.orders_id) {
                            job.is_removed = 1
                        }
                        return job
                    })
                    this.$toastr.success(data.message || "Hủy thành công")
                } else {
                    this.$toastr.error(catchError(data))
                }
            }
        },
        convertDate(date) {
            return moment(date).format("DD-MM-YYYY hh:mm:ss")
        },
        speedName(speed) {
            switch (speed) {
                case "low":
                    return "Chậm"
                case "normal":
                    return "Trung bình"
                case "medium":
                    return "Thông thường"
                case "high":
                    return "Nhanh"
                default:
                    return speed
            }
        }
    }
}
</script>
