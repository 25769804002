<template>
    <div class="d-flex flex-column mt-2 p-2 border rounded">
        <p class="font-bold">Chọn tốc độ cho gói SV3:</p>
        <label :for="speed.speed" class="d-flex align-items-center" v-for="(speed, index) in speeds" :key="index">
            <input
                type="radio"
                :name="speed.speed"
                :id="speed.speed"
                class="mr-2"
                :value="speed.speed"
                v-model="chosenSpeed"
            />
            <span>{{ speedName(speed.speed) }} ({{ speed.price }} mCoin)</span>
        </label>
    </div>
</template>

<script>
export default {
    props: { speeds: Array, type: String },
    name: "Sv3Speeds",
    data() {
        return {
            chosenSpeed: "low"
        }
    },
    watch: {
        speeds() {
            this.chosenSpeed = "low"
        },
        type() {
            this.chosenSpeed = "low"
        },
        chosenSpeed(value) {
            this.$emit("updateSpeedSv3", value)
        }
    },
    computed: {
        defaultSpeedObj() {
            return this.speeds.find(obj => {
                return obj.default == 1
            })
        }
    },
    created() {
        this.chosenSpeed = this.defaultSpeedObj.speed || "low"
    },
    methods: {
        speedName(speed) {
            switch (speed) {
                case "low":
                    return "Chậm"
                case "normal":
                    return "Trung bình"
                case "medium":
                    return "Thông thường"
                case "high":
                    return "Nhanh"
                default:
                    return speed
            }
        }
    }
}
</script>

<style lang="scss" scoped></style>
