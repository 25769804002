<template>
    <div>
        <h4 class="bold text-uppercase">{{ $route.name }}</h4>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a
                    class="nav-link active"
                    @click="handleShowPanel('list')"
                    id="home-tab"
                    data-toggle="tab"
                    href="#home"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                    >Tạo tiến trình</a
                >
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    @click="handleShowPanel('logs')"
                    id="profile-tab"
                    data-toggle="tab"
                    href="#profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                    >Nhật ký Order</a
                >
            </li>
            <!-- <li class="nav-item">
                <a
                    class="nav-link"
                    @click="handleShowPanel('logs-sv4')"
                    id="log-sv4-tab"
                    data-toggle="tab"
                    href="#log-sv4"
                    role="tab"
                    aria-controls="log-sv4"
                    aria-selected="false"
                    >Nhật ký Order SV4</a
                >
            </li> -->
            <li class="nav-item">
                <a
                    class="nav-link"
                    @click="handleShowPanel('logs-sv3')"
                    id="log-sv3-tab"
                    data-toggle="tab"
                    href="#log-sv3"
                    role="tab"
                    aria-controls="log-sv3"
                    aria-selected="false"
                    >Nhật ký Order SV3</a
                >
            </li>
        </ul>
        <div class="tab-content mt-3" id="myTabContent">
            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <div class="row">
                    <div v-if="type_default == 'like_page_sv3' && !checkedId" class="col-md-8">
                        <div class="card card-orange mt-2">
                            <div class="card-body p-3">
                                <h6 class="bold font-18 text-center red">
                                    Hướng dẫn kiểm tra ID Fanapge trước khi tăng Like
                                </h6>
                                <h6 class="font-14 mb-0">
                                    <span class="bold">Bước 1:</span> Lấy ID Fanapage và nhập vào ô Input đầu tiên
                                </h6>
                                <h6 class="font-14 mb-0">
                                    <span class="bold">Bước 2:</span> Lấy 1 tài khoản Facebook bất kỳ và Like trang
                                    Fanpage.
                                </h6>
                                <h6 class="font-14 mb-0">
                                    <span class="bold">Bước 3:</span> Lấy ID tài khoản Facebook vừa Like Fanpage và nhập
                                    vào ô Input thứ 2.
                                </h6>
                                <h6 class="font-14">
                                    <span class="bold">Bước 4:</span> Click Kiểm Tra để kiểm tra Fanpage có thể tăng
                                    Like hay không.
                                </h6>
                            </div>
                        </div>
                        <div class="row form-group mt-3">
                            <div class="col-md-4 col-12">
                                <h6 class="bold mb-0">
                                    ID
                                    {{
                                        type_default == "followe_corona"
                                            ? "trang cá nhân"
                                            : type_default == "like_page_corona"
                                            ? "Fanpage"
                                            : "bài viết"
                                    }}
                                    cần tăng Like:
                                </h6>
                            </div>
                            <div class="col-md col-12">
                                <input v-model="object_id" type="text" class="form-control input-light" />
                                <div v-if="type_default !== 'like_page_corona'" class="card card-orange mt-2">
                                    <div class="card-body p-3">
                                        <h6 class="font-14 mb-0">
                                            <label class="mb-0 text-danger font-bold"
                                                >Get ID Facebook từ Link nhanh tại
                                                <a
                                                    href="https://findids.net/"
                                                    target="_blank"
                                                    class="bold font-14 mb-0 blue"
                                                    >Findids.net</a
                                                ></label
                                            >
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group-lg form-group">
                            <div class="col-md-4 col-12">
                                <h6 class="bold mb-0">ID Facebook đã Like Page:</h6>
                            </div>
                            <div class="col-md col-12">
                                <input v-model="facebook_id" type="text" class="form-control input-light" />
                                <div class="row">
                                    <div class="col-md-6 col-12">
                                        <button
                                            @click="checkIDPage()"
                                            class="btn btn-block btn-dark-blue btn-rounded bold mt-3"
                                            type="submit"
                                        >
                                            Kiểm tra
                                        </button>
                                    </div>
                                    <div class="col-md-6 col-12">
                                        <button
                                            @click="checkedId = !checkedId"
                                            class="btn btn-block btn-orange btn-rounded bold mt-3"
                                            type="submit"
                                        >
                                            Bỏ qua
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="col-md-8">
                        <div class="form-group">
                            <div class="card card-orange mb-3">
                                <div class="card-body p-3 text-left">
                                    <h6 class="font-14 green">
                                        Gói Facebook Giá Rẻ là gói
                                        <span class="text-danger bold">(KHÔNG BẢO HÀNH)</span> hỗ trợ các bạn trong thời
                                        gian thị trường bị phá giá để có thể cạnh tranh với các Web khác. Khi thị trường
                                        ổn định, hệ thống sẽ đóng gói hỗ trợ này để cân bằng với giá thị trường. Hệ
                                        thống tăng tối đa <span class="text-danger bold">50.000</span> đối với gói
                                        Facebook Giá Rẻ.
                                        <!-- Các gói khác hệ thống sẽ Reset vào <span class="text-danger bold">(12H TRƯA MỖI NGÀY)</span> -->
                                    </h6>
                                    <div v-if="counter_today && max_today">
                                        <hr class="bg-danger my-2" />
                                        <h6
                                            v-for="(item, index) in type_count"
                                            :key="index"
                                            class="mb-0 text-danger text-uppercase bold text-center"
                                        >
                                            Số lượng {{ (type_default + item).toUpperCase() }} đã tăng trong ngày:
                                            {{ counter_today[type_default + item] | formatNumber }}/{{
                                                max_today[type_default + item] | formatNumber
                                            }}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">
                                    Link hoặc ID
                                    {{
                                        type_default == "follow_sv3"
                                            ? "trang cá nhân"
                                            : type_default == "like_page_sv3"
                                            ? "Fanpage"
                                            : "bài viết"
                                    }}:
                                </h6>
                            </div>
                            <div class="col-md col-12">
                                <input
                                    v-model="object_id"
                                    type="text"
                                    class="form-control input light"
                                    placeholder="Nhập link hoặc ID cần tăng"
                                />
                                <div class="card card-orange mt-2">
                                    <div class="card-body p-3">
                                        <h6 class="font-14 mb-0">
                                            <label class="mb-0 text-danger font-bold"
                                                >Get ID Facebook từ Link nhanh tại
                                                <a
                                                    href="https://findids.net/"
                                                    target="_blank"
                                                    class="bold font-14 mb-0 blue"
                                                    >Findids.net</a
                                                ></label
                                            >
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="type_default != 'comment' && type_default != 'review'" class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Số lượng cần tăng:</h6>
                            </div>
                            <div class="col-md col-12">
                                <input v-model="quantity" type="number" class="form-control input-light" />
                                <div v-if="type_default != 'view'" class="card card-white mt-2">
                                    <div class="card-body p-3">
                                        <h6 class="font-13 text-danger font-bold">
                                            Lưu ý: hệ thống hoạt động theo hình thức order tức là bạn order số lượng bao
                                            nhiêu thì hệ thống sẽ tự động lấy trong cơ sở dữ liệu ra số lượng người
                                            tương ứng để like, follow,... cho bạn.
                                        </h6>
                                        <h6 class="font-13 text-danger font-bold">
                                            Nên nếu nick của họ bị khóa (checkpoint) trong lúc tăng sẽ gây tụt trong lúc
                                            buff làm lượng like lên không đủ số lượng cần tăng là bình thường.
                                        </h6>
                                        <h6 class="font-13 text-danger font-bold mb-0">
                                            Do đó, vui lòng buff dư thêm 30 - 50% trên tổng số lượng để tránh tụt hoặc
                                            chọn gói bảo hành để được hoàn tiền nếu tụt. Chúng tôi sẽ không bảo hành với
                                            bất cứ lý do nào.
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="type_default != 'comment' && type_default != 'review'" class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Vui lòng chọn server</h6>
                            </div>
                            <div class="col-md col-12">
                                <div class="card card-white ">
                                    <div class="card-body p-3">
                                        <div class="server-container">
                                            <label for="sv3" class="d-block"
                                                ><input type="radio" id="sv3" value="sv3" v-model="server" />
                                                <strong class="text-danger font-bold mr-1 ml-1"> Sv3</strong
                                                ><span class="bagde badge-primary px-1 rounded font-bold mr-1 p-1"
                                                    >{{ priceSv3 }} mCoin</span
                                                ><span> (like clone nuôi, tốc độ nhanh, không bảo hành)</span></label
                                            >
                                            <facebook-sv-3-speed-vue
                                                v-if="server === 'sv3' && speedsSv3.length > 0"
                                                :speeds="speedsSv3"
                                                :type="type"
                                                @updateSpeedSv3="updateSpeedSv3"
                                            />
                                            <!-- <label v-if="pricesSv4.length > 0" for="sv4" class="mt-3 d-block mb-0"
                                                ><input type="radio" id="sv4" value="sv4" v-model="server" />
                                                <strong class="text-danger font-bold mr-1 ml-1"> Sv4</strong
                                                ><span class="bagde badge-primary px-1 rounded font-bold mr-1 p-1"
                                                    >{{ priceSv4 }} mCoin</span
                                                ><span>
                                                    (clone việt nuôi chậm - lên sau 1h, max 100k/Sv, không bảo hành
                                                    )</span
                                                ></label
                                            >
                                            <facebook-sv-4-speed-vue
                                                v-if="server === 'sv4' && speedsSv4.length > 0"
                                                :speeds="speedsSv4"
                                                :type="type"
                                                @updateSpeedSv4="updateSpeedSv4"
                                            /> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="type_default != 'view'" class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Giá tiền mỗi tương tác:</h6>
                                <h6 class="text-danger bold font-13">
                                    Giá thấp nhất: {{ price_default }} {{ currency }}
                                </h6>
                            </div>
                            <div class="col-md col-12">
                                <input
                                    type="number"
                                    placeholder="Giá tiền bạn muốn bid cho mỗi tương tác"
                                    v-model="price"
                                    class="form-control input-light bold"
                                />
                                <div class="card card-white mt-2">
                                    <div class="card-body p-3">
                                        <h6 class="font-13 mb-0 text-justify">
                                            Mẹo nhỏ: Hệ thống ưu tiên chạy các job giá cao trước nên nếu bạn cần gấp bạn
                                            có thể set giá job của mình cao hơn 1 vài đồng để chạy nhanh nhất có thể
                                            nhé.
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Ghi chú:</h6>
                            </div>
                            <div class="col-md col-12 text-right">
                                <textarea
                                    class="form-control input-light"
                                    rows="2"
                                    v-model="notes"
                                    placeholder="Nhập nội dung ghi chú về tiến trình của bạn"
                                ></textarea>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-12 text-center bold">
                                <div class="card card-info">
                                    <div class="card-body">
                                        <h5 class="font-bold">
                                            Tổng:
                                            <span class="bold"
                                                ><number
                                                    ref="number1"
                                                    :from="0"
                                                    :to="totalPrice"
                                                    :format="theFormat"
                                                    :duration="1"
                                                    :delay="0"
                                                    easing="Power1.easeOut"
                                                />
                                                {{ currency }}</span
                                            >
                                        </h5>
                                        <h6 class="mb-0">
                                            Bạn sẽ buff
                                            <span class="bold">{{ quantity }} {{ packageName(type) }}</span> với giá
                                            <span class="green bold">{{ priceSingle | formatNumber }}</span>
                                            {{ currency }} / {{ packageName(type) }}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-12 text-center bold">
                                <button
                                    @click="order()"
                                    :class="['btn btn-block btn-dark-blue btn-lg btn-rounded bold']"
                                    type="submit"
                                >
                                    Tạo tiến trình
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div v-if="type_default != 'view'" class="card card-red">
                            <div class="card-body color-info">
                                <p class="font-bold">Chú ý:</p>
                                <p class="font-bold">
                                    - Ngiêm cấm Buff các ID Seeding có nội dung vi phạm pháp luật, chính trị, đồ trụy...
                                    Nếu cố tình buff bạn sẽ bị trừ hết tiền và band khỏi hệ thống vĩnh viễn, và phải
                                    chịu hoàn toàn trách nhiệm trước pháp luật.
                                </p>
                                <p>
                                    - Hệ thống sử dụng 99% tài khoản người VN, fb thật để tương tác like, comment,
                                    share....
                                </p>
                                <p>
                                    - Thời gian làm việc (tăng seeding) và bảo hành tính từ ngày bắt đầu cho đến ngày
                                    kết thúc job, tối đa là 1 tuần
                                </p>
                                <p>
                                    - Hết thời gian của job đã order nếu không đủ số lượng hệ thống sẽ tự động hoàn lại
                                    số tiền seeding chưa tăng cho bạn <b class="font-bold">trong vòng 1 đến 3 ngày</b>
                                </p>
                                <p>
                                    - Vui lòng lấy đúng id bài viết, công khai và check kỹ job tránh tạo nhầm, tính năng
                                    đang trong giai đoạn thử nghiệm nên sẽ không hoàn tiền nếu bạn tạo nhầm
                                </p>
                                <p v-if="type_default == 'like_page_corona'" class="font-bold">
                                    - Không tăng Like cho Page có thời gian tạo dưới 1 tháng.
                                </p>
                                <p v-else>
                                    - Chỉ nhận id bài viết công khai không nhập id album, id comment, id trang cá nhân,
                                    id page,...
                                </p>
                                <p class="font-bold">
                                    - Nhập id lỗi hoặc trong thời gian chạy die id thì hệ thống không hoàn lại tiền.
                                </p>
                                <p>
                                    - Mỗi id có thể
                                    <span class="font-bold">Buff tối đa 10 lần trên hệ thống để tránh Spam</span>, max
                                    1k trong ngày (hoặc hơn nếu order giá cao), trên 1k thời gian lên chậm hơn trong
                                    vòng 7 ngày
                                </p>
                            </div>
                        </div>
                        <div v-else class="card card-red">
                            <div class="card-body color-info">
                                <p class="font-bold">
                                    - Ngiêm cấm Buff các ID Seeding có nội dung vi phạm pháp luật, chính trị, đồ trụy...
                                    Nếu cố tình buff bạn sẽ bị trừ hết tiền và band khỏi hệ thống vĩnh viễn, và phải
                                    chịu hoàn toàn trách nhiệm trước pháp luật.
                                </p>
                                <strong
                                    ><b><i class="mr-1"></i>Hướng dẫn: </b></strong
                                >
                                <br />- Số lượng view tối thiểu mỗi lần mua là 500 view , tối đa là 100,000 View.
                                <br />- Hệ thống tăng View và hỗ trợ tăng mắt LiveStream trong quá trình chạy View.
                                <br />- Để giống người xem thật, số lượng View sẽ được điều chỉnh lên xuống ngẫu nhiên
                                quanh số View của bạn mua.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                <facebooklogs :panel="panel" :type="type"></facebooklogs>
            </div>
            <div class="tab-pane fade" id="log-sv3" role="tabpanel" aria-labelledby="log-sv3-tab">
                <facebookLogsSv3Vue :panel="panel" :type="sv3Type"></facebookLogsSv3Vue>
            </div>
        </div>
    </div>
</template>
<script>
import * as toolApi from "../../../../api/tool"
// import * as sv4Api from "../../../../api/services/facebook-sv4"
import * as sv3Api from "../../../../api/services/facebook-sv3"
import { facebookPostUrlToId, getUserIdFacebookFromUrl, catchError } from "../../../../helpers"
import { checkLikePage, convertUsernameToFbId } from "../../../../api/services/facebook"
import { createOrder } from "../../../../api/services/facebook-sv3"
import facebooklogs from "./facebook-logs"
import menus from "../../../../config/menu"
import facebookSv3SpeedVue from "./facebook-sv3-speed.vue"
// import facebookSv4SpeedVue from "./facebook-sv4-speed.vue"
// import facebookLogsSv4Vue from "./facebook-logs-sv4.vue"
import facebookLogsSv3Vue from "./facebook-logs-sv4.vue"

export default {
    name: "facebook-sv3-order",
    components: { facebooklogs, facebookLogsSv3Vue, facebookSv3SpeedVue },
    data() {
        return {
            facebook_id: "",
            checkedId: true,
            object_id: "",
            type: "",
            type_default: "",
            type_corona: "",
            is_warranty: false,
            time_start: new Date().toISOString(),
            minDatetime: new Date().toISOString(),
            time_expired: "",
            date_warranty: 7,
            gender: "",
            age: {
                min: 0,
                max: 0
            },
            friend: {
                min: 0,
                max: 0
            },
            price: 0,
            is_filter: false,
            panel: "order",
            quantity: 100,
            price_target: 0,
            price_default: 100,
            notes: "",
            list_messages: "",
            counter_today: null,
            max_today: null,
            type_count: ["", "_0", "_5", "_10"],
            server: "sv3", // sv3/sv4
            priceSv3: 0,
            priceSv4: 0,
            pricesSv3: [],
            pricesSv4: [],
            speedSv4: "low",
            menus
        }
    },
    computed: {
        currency() {
            return this.$store.state.site.site.currency
        },
        configSite() {
            return this.$store.state.site.site.config || false
        },
        prices() {
            // list tool excluding sv4 :)(:
            let tool = this.$store.state.tools.tools.find(tool => tool.id === 69)
            return tool.prices
        },
        priceSingle() {
            let price = parseInt(this.price)
            let getPackage = "seeding_by_workers_query_config"
            if (this.type === "follow" || this.type === "like_page_corona") {
                getPackage = "seeding_by_workers_query_" + this.type + "_config"
            }
            let tool = this.$store.state.tools.tools.find(tool => tool.id === 69)
            if (this.is_filter) {
                let priceTarget = tool.prices.find(price => price.package_name.includes(getPackage))
                let priceTargets = priceTarget ? priceTarget.price : 50
                if (this.type === "follow" || this.type === "like_page_corona") {
                    priceTargets = priceTargets < 100 ? 100 : priceTargets
                } else {
                    priceTargets = priceTargets < 20 ? 20 : priceTargets
                }
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.price_target = priceTargets
                if (this.gender.toString() !== "") {
                    price += (parseInt(this.price) * parseInt(priceTargets)) / 100
                }
                if (this.age.min > 0) {
                    price += (parseInt(this.price) * parseInt(priceTargets)) / 100
                }
                if (this.friend.min > 0) {
                    price += (parseInt(this.price) * parseInt(priceTargets)) / 100
                }
            }
            return Math.ceil(price)
        },
        totalPrice() {
            return this.priceSingle * this.quantity
        },
        router() {
            return this.$route.path
        },
        typeText() {
            return this.type.replace(/_10|_5|_0/g, "")
        },
        sv3Type() {
            const { type } = this
            switch (type) {
                case "like_sv3":
                    return "like_sv3"
                case "like_page_sv3":
                    return "like_page_sv3"
                case "follow_sv3":
                    return "follow_sv3"
                default:
                    return type
            }
        },
        priceSv4ObjWithType() {
            const obj = this.pricesSv3.find(item => {
                return item.package_name == this.sv3Type
            })
            return obj
        },
        speedsSv3() {
            if (this.priceSv4ObjWithType) {
                return this.priceSv4ObjWithType.speed.map(item => {
                    item.package_name = this.priceSv4ObjWithType.package_name
                    return item
                })
            }
            return []
        }
    },
    watch: {
        router(value) {
            this.returnType(value)
        },
        list_messages(value) {
            this.quantity = value ? value.match(/.+/g).length : 0
        },
        async object_id() {
            if (this.object_id.indexOf("facebook.com") > -1) {
                if (this.type === "follow_sv3" || this.type === "like_page_sv3") {
                    this.object_id = getUserIdFacebookFromUrl(this.object_id)
                } else this.object_id = facebookPostUrlToId(this.object_id)
            }
        },
        type(value) {
            this.getPriceSeedingType(value)
            this.getPriceDefault(this.type)
            this.object_id = ""
            this.note = ""
            this.server = "sv3"
        },
        type_corona() {
            this.getPriceSeedingType(this.type)
            this.getPriceDefault(this.type)
            this.server = "sv3"
        },
        server() {
            this.getPriceSeedingType(this.type) // final price
            this.getPriceDefault(this.type)
        },
        pricesSv3() {
            this.getPriceDefault(this.type)
        },
        speedSv3() {
            this.getPriceSeedingType(this.type)
        }
    },
    async created() {
        this.returnType(this.$route.path)
        this.getPriceSeedingType(this.type)
        this.getPriceDefault(this.type)
        this.time_expired = this.increaseDateByNumber(this.time_start, this.date_warranty)
        this.getToolsSv3()
    },
    methods: {
        updateSpeedSv3(value) {
            this.speedSv3 = value
            let newPrice = this.priceSv4ObjWithType.speed.find(item => {
                return item.speed === value
            })
            this.priceSv3 = newPrice.price
            this.price = newPrice.price
            this.price_default = newPrice.price
        },
        packageName(type) {
            switch (type) {
                case "like_sv3":
                    return "like"
                case "follow_sv3":
                    return "follow"
                case "like_page_sv3":
                    return "like_page"
                default:
                    return type
            }
        },
        returnType(value) {
            const fbAutoMenu = this.menus.find(menu => {
                return menu.id === "facebook-auto"
            })
            const sv3Menu = fbAutoMenu.childs.find(menu => {
                return menu.id == "facebook-sv3"
            })
            const matchedChild = sv3Menu.childs.find(child => {
                return child.to === value
            })
            this.type = matchedChild.type
            this.type_default = this.type
            this.is_warranty = false
        },
        async checkIDPage() {
            if (!this.object_id || !this.facebook_id) {
                this.$swal("Thông báo", "Hãy làm đúng yêu cầu các bước trước khi kiểm tra!", "error")
                return
            }
            let postData = {
                object_id: this.object_id,
                fb_id: this.facebook_id
            }
            let data = await checkLikePage(postData)
            if (data.status === 200 && data.success) {
                this.checkedId = true
                await this.$swal("Thông báo", "Fanpage có thể tăng Like, hãy thực hiện các bước tiếp theo", "success")
            } else {
                await this.$swal("Thông báo", catchError(data), "error")
            }
        },
        order: async function() {
            const { server } = this
            const finalType = server === "sv3" ? this.type : this.sv4Type
            if (this.price >= 2 * this.price_default) {
                this.$swal(
                    "Thông báo",
                    "Giá bạn đang chọn cao hơn giá bình thường nhiều lần, vui lòng chỉnh lại giá!",
                    "error"
                )
                return
            }
            if (server === "sv3") {
                if (!this.object_id || this.object_id === "") {
                    this.$swal(
                        "Thông báo",
                        "Vui lòng nhập link hoặc id " +
                            (this.type === "like_sv3"
                                ? "bài viết"
                                : this.type === "follow_sv3"
                                ? "trang cá nhân"
                                : "page"),
                        "error"
                    )
                    return
                }
            } else {
                if (!this.object_id || this.object_id === "") {
                    this.$swal(
                        "Thông báo",
                        "Vui lòng nhập link hoặc id " +
                            (this.sv4Type === "like_sv4"
                                ? "bài viết"
                                : this.sv4Type === "follow_sv4"
                                ? "trang cá nhân"
                                : "page"),
                        "error"
                    )
                    return
                }
            }

            this.$swal({
                title: "Xác nhận",
                text: `Bạn có chắc chắn muốn Order ${this.quantity} ${this.packageName(
                    finalType
                ).toUpperCase()} với giá: ${this.priceSingle} ${this.currency}/1 ${this.packageName(
                    finalType
                ).toUpperCase()} ?`,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Đồng Ý",
                cancelButtonText: "Không",
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.orderConfirm(server)
                } else if (result.dismiss === this.$swal.cancel) {
                    this.$swal("Thông tin", "Bạn đã hủy bỏ Order hiện tại!", "info")
                }
            })
        },
        orderConfirm: async function(server = "sv3") {
            let postData = {
                link: "https://www.facebook.com/" + this.object_id,
                type: server === "sv3" ? this.type : this.sv4Type,
                object_id: this.object_id,
                quantity: this.quantity,
                prices: this.price * this.quantity,
                notes: this.notes
            }
            let data
            if (server === "sv3") {
                data = await createOrder(postData)
            } else {
                postData.speed = this.speedSv4
                data = await sv3Api.createOrder(postData)
            }
            if (data.status === 200 && data.success) {
                this.$swal("Thành công", data.message, "success")
            } else {
                if (data.message) {
                    this.$swal("Lỗi", data.message, "error")
                } else {
                    this.$swal("Lỗi", catchError(data), "error")
                }
            }
        },
        getPriceSeedingType: function(type) {
            type = this.type_corona.length > 0 ? type + "_" + this.type_corona : type
            let price
            if (this.server === "sv3") {
                price = this.prices.find(price => price.package_name == type)
                let obj = price.speed.find(item => item.default == 1)
                this.priceSv3 = obj.price || 0
                this.price_default = obj.price || 0
                this.price = obj.price || 0
            } else {
                price = this.speedsSv4.find(item => item.speed == this.speedSv4)
                this.priceSv4 = price.price || 0
            }
        },
        getPriceDefault: function(type) {
            let toolSv3Obj = this.prices.find(price => price.package_name == type)
            const obj = toolSv3Obj.speed.find(item => item.default == 1)
            this.priceSv3 = obj.price || 0
            this.price_default = obj.price || 0
        },
        theFormat(number) {
            return number
                ? Math.round(Number(number))
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : 0
        },
        increaseDateByNumber(date, number = 0) {
            const dateInit = new Date(date).valueOf()
            const milisecondIncrease = number * 24 * 60 * 60 * 1000
            return new Date(dateInit + milisecondIncrease).toISOString()
        },
        handleShowPanel: async function(panel) {
            this.panel = panel
        },
        getFbId: async function(link) {
            if (link.match(/facebook/)) {
                this.$toastr.success("Hệ thống đang tự động get id cho link facebook bạn nhập vào!")
                if (link.indexOf("profile.php") > -1) {
                    var regex = (link + "&").match(/id=(\d*?)&/)
                    if (regex.length === 2) {
                        this.$toastr.success("Đã tự động get id cho bạn")
                        this.object_id = regex[1]
                        return true
                    } else {
                        return false
                    }
                } else {
                    var username = link.replace(
                        /https|http|\/|\\|www\.|mbasic\.|m\.facebook.com|facebook\.com|:|\?.*/gm,
                        ""
                    )
                    let data = await convertUsernameToFbId(username)
                    if (data.status === 200 && data.success) {
                        this.$toastr.success("Đã tự động get id cho bạn")
                        this.object_id = data.data.id
                        return true
                    } else {
                        return false
                    }
                }
            } else return true
        },
        getToolsSv3: async function() {
            const response = await toolApi.getTools()
            if (response) {
                if (response.success) {
                    let tool = response.data.find(tool => tool.id == 69)
                    if (tool) {
                        this.pricesSv3 = tool.prices || []
                    } else {
                        this.pricesSv3 = []
                    }
                } else {
                    this.pricesSv3 = []
                }
            } else {
                this.pricesSv3 = []
            }
        }
    }
}
</script>
